// src/components/Footer.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation('footer');

  return (
      <footer className="footer">
        <Container className="footer-container">
          {/* Thông tin công ty */}
          <div className="footer-column">
            <Typography variant="h6">9Bricks</Typography>
            <Typography variant="body2">
              {t('Address')}: {t('Info')} <br />
              {t('Phone')}: +84 765 558 058 <br />
              {t('Email')}: support@9bricks.com
            </Typography>
          </div>

          {/* Expertise Links */}
          <div className="footer-column">
            <Typography variant="h6">{t('Expertise')}</Typography>
            <Typography
                variant="body2"
                component={Link}
                to="/software-solutions"
                className="footer-link"
            >
              {t('SoftwareSolutions')}
            </Typography>
          </div>

          {/* Products Links */}
          {/* <div className="footer-column">
            <Typography variant="h6">{t('Products')}</Typography>
            <Typography
                variant="body2"
                component={Link}
                to="/products"
                className="footer-link"
            >
              {t('TAVISPersonalAssistant')}
            </Typography>
          </div> */}

          {/* Resources Links */}
          <div className="footer-column">
            <Typography variant="h6">{t('Resources')}</Typography>
            <Typography
                variant="body2"
                component={Link}
                to="/our-journey"
                className="footer-link"
            >
              {t('OurJourney')}
            </Typography>
            <Typography
                variant="body2"
                component={Link}
                to="/insights"
                className="footer-link"
            >
              {t('Insights')}
            </Typography>
            <Typography
                variant="body2"
                component={Link}
                to="/jobs"
                className="footer-link"
            >
              {t('Jobs')}
            </Typography>
            <Typography
                variant="body2"
                component={Link}
                to="/about-us"
                className="footer-link"
            >
              {t('AboutUs')}
            </Typography>
          </div>

          {/* Legal Links */}
          <div className="footer-column">
            <Typography variant="h6">{t('Legal')}</Typography>
            <Typography variant="body2" component={Link} to="/privacy-policy" className="footer-link">
              {t('PrivacyPolicy')}
            </Typography>
            <Typography variant="body2" component={Link} to="/cookie-policy" className="footer-link">
              {t('CookiePolicy')}
            </Typography>
            <Typography variant="body2" component={Link} to="/terms-and-conditions" className="footer-link">
              {t('TermsAndConditions')}
            </Typography>
          </div>
        </Container>
        {/* Divider */}
        <div className="footer-divider"></div>
        <Typography variant="body2" className="footer-bottom">
          © 2024 9Bricks | {t('HoChiMinhCity')}
        </Typography>
      </footer>
  );
};

export default Footer;
